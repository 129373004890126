.compounded_main_wrapp {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.compounded_ineer_sec {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* width: 100%; */
  min-width: 400px;
}

.compounded_ineer_sec > h4 {
  color: #363636;
  font-size: 1.75rem;
  font-weight: 700;
  width: 100%;
}

.compounded_ineer_sec > p {
  color: #363636;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  margin-bottom: 0;
}

.compounded_ineer_sec_graypart {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f5f5f5;
}

.compounded_ineer_sec_graypart > div {
  border-bottom: 1px solid #ebbd41;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 2rem;
  width: 100%;
  padding: auto;
  margin: 1rem 0;
  padding-bottom: 1rem;
  flex-wrap: wrap;
}

.compounded_ineer_sec_graypart > div > h5 {
  color: #ebbd41;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 125%;
}

.compounded_ineer_sec_graypart > div > div {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  width: 50%;
}

.compounded_ineer_sec_graypart > div > div > h5 {
  color: #363636;
  font-size: 1.45rem;
  font-weight: 700;
  line-height: 125%;
}

.compounded_ineer_sec_graypart > div > div > span {
  color: #3a75a0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 125%;
}

@media (max-width: 1000px) {
  .compounded_main_wrapp {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .compounded_ineer_sec > h4 {
    font-size: 28px;
  }

  .compounded_ineer_sec_graypart > div > div {
    min-width: 350px;
  }
  .compounded_ineer_sec_graypart > div > div > h5 {
    font-size: 1.2rem;
  }

  .compounded_ineer_sec {
    /* margin-top: 1rem; */
    /* margin-top: 0; */
    min-width: 100%;

  }

  .compounded_ineer_sec_graypart {
    padding: 10px;
    width: 95%;
    margin: auto;
    
    /* margin: 10px; */
  }

  .compounded_ineer_sec > p {
    width: 90%;
    margin-bottom: 0;
  }

  .compounded_ineer_sec_graypart > div {
    width: 90%;
  }
  .compounded_ineer_sec_graypart > div > div > h5 {
    font-weight: 600;
  }
  .compounded_main_wrapp {
    gap: 3rem;
  }

}
@media (max-width: 480px) {
.compounded_ineer_sec > p {
  width: 95%;
  margin: auto ;
  text-align: justify;
}
.compounded_main_wrapp {
  gap: 2.5rem;
}
.compounded_ineer_sec {
  gap: 1.5rem;
  }
}